
import axios from "axios";

axios.defaults.headers['Content-Type'] = 'application/json';

const Axios = axios.create({
    baseURL: "https://api.kodihomes.co.ke/api"
});


export default Axios;