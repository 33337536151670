import { useMutation } from 'react-query';
import Axios from '../Axios/UserAxios';
type PaymentType={
    tenantId: string,
    packageId: string,
    paymentMethod: string,
    amount:string,
    phoneNumber: string,
    remoteKey:string
}
async function MpesaPayment({tenantId,packageId,paymentMethod,amount,phoneNumber,remoteKey}:PaymentType){
    try {
        const response = await Axios.post(`/remote/payment/${remoteKey}/${packageId}`,JSON.stringify({
            tenantId,
            packageId,
            paymentMethod,
            amount,
            phoneNumber,
            macaddress: "none"
        }));
        return response.data;
    } catch (error) {
        return error;
    }
}

export default function MpesaMutation() {
    return useMutation({
        mutationFn: MpesaPayment,
        mutationKey: ['mpesaPayment'],
    })
}

