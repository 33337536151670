type modal = {
    open: Function,
    item: any
}
/*function convertHoursToReadable(hours: any) {
    if (hours >= 24 * 30 * 24) {
        // More than a year
        return (
            Math.floor(hours / (24 * 30 * 24)) +
            (Math.floor(hours / (24 * 30 * 24)) === 1 ? " yr" : " yrs")
        );
    } else if (hours >= 24 * 30) {
        // More than a month
        return (
            Math.floor(hours / (24 * 30)) +
            (Math.floor(hours / (24 * 30)) === 1 ? " mo" : " mos")
        );
    } else if (hours >= 24 * 7) {
        // More than a week
        return (
            Math.floor(hours / (24 * 7)) +
            (Math.floor(hours / (24 * 7)) === 1 ? " wk" : " wks")
        );
    } else if (hours >= 24) {
        // More than a day
        return (
            Math.floor(hours / 24) + (Math.floor(hours / 24) === 1 ? " day" : " days")
        );
    } else {
        // Less than a day
        return hours + (hours === 1 ? " hr" : " hrs");
    }
}*/
export default function PayCard({ open, item }: modal) {
    const { price, name, packageType, speed, users } = item;
    return <div className="package">
        <h3 className="pay-h3">{name} {packageType}</h3>
        <p>{'Ksh ' + price}</p>
        <ul>
            <li>users {users}</li>
            <li>Speed {speed}</li>
        </ul>

        <button onClick={() => open(item)}>Buy Now</button>
    </div>
}