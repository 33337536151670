import React from "react"
export default function Footer(){
    return  <footer>
    <div className="contact">
        <p>Contact us: 0736047611</p>
    </div>
    <div className="social">
        <a href="#none"><i className="fab fa-facebook-f"></i></a>
        <a href="#none"><i className="fab fa-twitter"></i></a>
        <a href="#none"><i className="fab fa-instagram"></i></a>
    </div>
    <div className="legal">
        <a href="https://mikrolinktech.com">Powere by Mikrolinktech</a>
    </div>
</footer>
}