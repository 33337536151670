
import PaymentForm from "../forms/PaymentForm"

export default function Modal({open=true,close=()=>{},item}:{open:boolean,close:Function,item:{
    tenantId: string,
    packageId: string,
    paymentMethod: string,
    amount: string,
}}) {

    return <>
   
        <div id="paymentModal"style={{display:open?"block":"none"}} className="modal">
            <div className="modal-content">
                <span onClick={()=>close()} className="close">&times;</span>
                <h2>Payment Details</h2>
                <PaymentForm item={item} close={close}/>

            </div>
        </div>

    </>
}