import React, { useEffect, useState } from "react";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import PayCard from "../components/card/PayCard";
import Modal from "../components/card/Modal";
import { useLocation } from 'react-router-dom';
import { isNull, isEqual} from 'lodash'
import { PackagesListQuery } from "../components/hooks/PackagesQuery";

export default function HomePage() {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [item, setParams] = useState<any>({});
    const remoteKey = "9DgtJuLL";
    const packageName = "hotspot";
    const handleOpenModal = (item: any) => {
        setModalOpen(!modalOpen);
        addFromModal(item)
    }
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const deviceId: any = searchParams.get('de');

    useEffect(() => {

        const packageId = searchParams.get('pa');
        const amount = searchParams.get('amt');
        const openModal = searchParams.get('open');
        const isOpenModal = ((isEqual(deviceId, null) || isNull(deviceId)) || (isEqual(packageId, null) || isNull(packageId)) || (isEqual(amount, null) || isNull(amount)) || (isEqual(openModal, null) || isNull(openModal)))
        if (isEqual(isOpenModal, false)) {
            const body = {
                tenantId: remoteKey,
                packageId: packageId,
                paymentMethod: "mpesa",
                amount: amount,
                deviceId
            };
            setModalOpen(openModal === "true");
            setParams(body);
        }

    }, [location.search]);
    const query = PackagesListQuery({
        packageName: packageName,
        remoteKey: remoteKey,
        deviceId: item?.deviceId
    })

    const addFromModal = (item: any) => {
        const params = new URLSearchParams(window.location.search);
        params.set('de', deviceId);
        params.set('pa', item?.id);
        params.set('amt', item.price);

        const newUrl = `${window.location.pathname}?${params}`;
        window.history.replaceState({}, '', newUrl);

    }
    const { data, isFetched } = query
    return <>
        <Header />
        <section className="login">
            <h2>Get your access vaucher</h2>

        </section>
        <section className="packages">
            <h2 className="advert">Explore Our Packages</h2>
            <div className="package-container">
                {isFetched && data?.data?.map((item: any) => <PayCard
                    open={handleOpenModal}
                    item={item}

                />)}

                <Modal open={modalOpen} close={() => setModalOpen(false)} item={item} />
            </div>
        </section>

        <Footer />

    </>
}