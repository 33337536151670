import { ErrorMessage, Field, Form, Formik } from "formik";
import MpesaValidation from "./paymentValidation";
import { CirclesWithBar } from "react-loader-spinner";
import MpesaMutation from "../hooks/paymentMutation";

export default function PaymentForm({ item ,close}: { item: any,close:Function }) {
    const mutation = MpesaMutation();
   
    return <>
        <div className="loader" id="loader">
            {mutation.isLoading && <CirclesWithBar
                height="100"
                width="100"
                color="#4fa94d"
                outerCircleColor="#4fa94d"
                innerCircleColor="#4fa94d"
                barColor="#4fa94d"
                ariaLabel="circles-with-bar-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />}


        </div>
        <Formik
            initialValues={{
                phoneNumber: ""
            }}
            validationSchema={MpesaValidation}
            onSubmit={async (values, { setSubmitting, setErrors, resetForm, }) => {
                var cleanedInput = values.phoneNumber.replace(/\D/g, "");
                
                if (/^(07|01|1|7|254|\+254)/.test(cleanedInput)) {
                    // If the input starts with "254" or "+254", remove it
                   var formattedInput = cleanedInput.replace(/^(254|\+254)/, "");

                   

                    if (/^(01|1|7)/.test(formattedInput)) {
                        formattedInput = "254" + formattedInput;
                    }
                   
                     await mutation.mutateAsync({
                        tenantId: item.tenantId,
                        packageId: item.packageId,
                        paymentMethod: "mpesa",
                        amount: item.amount,
                        phoneNumber: formattedInput,
                        remoteKey: item.tenantId
                    });
                    setTimeout(()=>{
                        close();
                        window.location.href="http://surfmtaani.login"
                    },2000)

                }
            }}
            enableReinitialize={true}

        >

            {({ errors, touched, handleBlur, handleChange, values, isSubmitting }) => {
                return (
                    <Form className="paymenyForm">
                        <h2 className="advert">PAY WITH MPESA</h2>
                        <Field
                            required
                            fullWidth
                            name="phoneNumber"
                            placeholder="Mpesa phonenumber"
                            type="tel"
                            id="phoneNumber"
                            autoComplete="phoneNumber"
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={<ErrorMessage name="phoneNumber" />}
                            error={errors.phoneNumber && touched.phoneNumber}
                            value={values.phoneNumber}
                        />

                        <button disabled={mutation.isLoading} type="submit">Make payment</button>
                    </Form>
                );
            }}
        </Formik>
    </>

}