

import { useQuery } from 'react-query';
import Axios from '../Axios/UserAxios';
type Packages={
    packageName:string,
    remoteKey:string,
    deviceId:string
}
async function getPackagesList({packageName,remoteKey,deviceId}:Packages) {
    try {
        const response = await Axios.get(`/remote/packages/${remoteKey}/${packageName}/${deviceId}`);
        return response.data;
    } catch (error) {
       // return await networkErrorHandler(error);
    }
}
export  function PackagesListQuery({packageName,remoteKey,deviceId}:Packages) {
    console.log(deviceId)
    return useQuery({
        queryFn:()=> getPackagesList({
            packageName,
            remoteKey,
            deviceId
        }),
        queryKey: ['PackagesList',packageName,remoteKey,deviceId]
    })
}